:root {
    --row-width: 1400px;
    --first-column-width: 300px;
    --row-container-height: 60px;
    --row-contents-height: 55px;
    --width-of-slots: 70px;
    --border-radius: 5px;
    --slot-width: 70px;
}

.progression-overview-container {
    font-family: Poppins, serif;
    z-index: 0;
    min-width: 1800px;
    width: 100vh;
    position: relative;
    vertical-align: top;
}

.progression-overview-container .progress-overview-tab {
    width: 100%;
    background-color: white;
    padding: 50px;
    margin-top: 20px;
}

.progression-overview-container .filter-buttons-container {
    position: relative;
    margin-left: 10px;
}

.progression-overview-container .filter-button-container {
    display: inline-block;
    margin-right: 20px;
    text-align: left;
}

.progression-overview-container .filter-button-container label {
    display: block;
    margin-bottom: 5px;
    font-weight: 800;
}

.progression-overview-container .shape {
    position: absolute;
    height: 90px;
    width: 65px;
    clip-path: polygon(25% 25%, 100% 25%, 100% 65%, 25% 65%, 0 45%);
    transform: rotate(-90deg);
    background-color: #047dc5;
    color: white;
    font-size: 18px;
    top: -8px;
    padding-top: 26px;
    padding-left: 12px;
    text-align: center;
    z-index: 2;
}

.progression-overview-container .p-tabview-panels, .p-tabview-nav-content {
    width: 1900px;
}

