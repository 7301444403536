.user-selector-container {
    height: 35px;
}
.user-selector-container .user-image {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 15px;
}

/*.user-selector-container .user-image img {*/
/*    width: 60px;*/
/*    height: 60px;*/
/*    border-radius: 50%;*/
/*}*/

.user-selector-container .missing-photo {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-size: 12px;
    margin-right: 15px;
    background-color: #cccccc;
}