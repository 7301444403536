:root {
    --content-container-background-color: #f2f3f3;
}

.keyresult-full-information-container {
    padding-top: 10px;
    height: 750px;
}

.keyresult-full-information-container .top-dialog-navigation-container {
    position: absolute;
    top: 0;
    width: 200px;
    height: 50px;
}

.keyresult-full-information-container .top-dialog-navigation-button {
    width: 100px;
    margin-right: 20px;
    text-align: center;
    padding-top: 10px;
    position: absolute;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 40px;
    color: white;
    cursor: pointer;
    font-weight: 600;
    /*box-shadow: 0 0.2rem 0.3rem rgba(0,0,0,0.3);*/
    background-color: #cccccc;
}

.keyresult-full-information-container .top-dialog-navigation-button:hover {
    font-weight: 700;
}

.keyresult-full-information-container .objective-title {
    font-size: 14px;
    font-weight: 200;
    color: #6d6e6d;
    text-transform: uppercase;
}

.keyresult-full-information-container .keyresult-title {
    font-size: 25px;
    font-weight: 600;
    margin-top: 10px;
    padding-bottom: 15px;
    border-bottom: 1px solid #cccccc;
}

.keyresult-full-information-container .what-to-do {
    font-size: 14px;
    font-weight: 200;
    display: inline-block;
    line-height: 20px;
}

.keyresult-full-information-container .content-container {
}

.keyresult-full-information-container .responsible-person img {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    margin-right: 20px;
}

.keyresult-full-information-container .responsible-person div {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    margin-right: 20px;
    background-color: #cccccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
}

.keyresult-full-information-container .body {
    width: 750px;
    min-height: 800px;
    margin-left: 35px;
    margin-right: 35px;
    overflow-y: scroll;
}

.keyresult-full-information-container .divider {
    margin-top: 20px;
    margin-bottom: 40px;
}

.keyresult-full-information-container .header-section table {
    border-collapse: collapse;
}

.keyresult-full-information-container .header2 {
    font-size: 16px;
    font-weight: 500;
    margin-top: 40px;
    margin-bottom: 15px;
}

.keyresult-full-information-container .team-container {
    margin-bottom: 40px;
    padding-bottom: 20px;
}

.keyresult-full-information-container .team-container .anchor-team {
    float: left;
    text-transform: uppercase;
}

.keyresult-full-information-container .anchor-team-name {
    font-family: Poppins, serif;
    font-weight: 600;
}    

.keyresult-full-information-container .team-member-container {
    width: 100%;
}

.keyresult-full-information-container .team-member-container img {
    float: right;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 10px;
}

.keyresult-full-information-container .team-container .team-label {
    float: right;
    vertical-align: middle;
    height: 20px;
    line-height: 20px;
    margin-right: 10px;
    background-color: rgb(179, 74, 111);
    color: white;
    border-radius: 5px;
    font-size: 10px;
    padding: 0 4px 0 4px;
}

.keyresult-full-information-container .update-information-container {
    margin-bottom: 20px;
}

.keyresult-full-information-container .edit-symbol {
    font-size: 20px;
    cursor: pointer;
    color: white;
    background-color: #6d6e6d;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    vertical-align: middle;
    text-align: center;
    float: right;
}

.keyresult-full-information-container .updated-by-user {
    height: 40px;
    line-height: 40px;
    vertical-align: middle;
    margin-bottom: 15px;
}

.keyresult-full-information-container .update-divider {
    /*border-bottom: 1px solid #cccccc;*/
}

.keyresult-full-information-container .updated-by-user-image img {
    width: 34px;
    height: 34px;
    border-radius: 100%;
    margin-right: 10px;
    float: left;
    object-fit: cover;
}

.keyresult-full-information-container .updated-by-user-image div {
    width: 34px;
    height: 34px;
    border-radius: 100%;
    margin-right: 10px;
    background-color: #cccccc;
    font-size: 15px;
    font-weight: 600;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

.keyresult-full-information-container .updates-container {
    position: relative;
    border: 1px solid rgb(226, 232, 240);
    background-color: white;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 200;
    float: left;
    padding: 20px;
    margin-bottom: 20px;
    width: 700px;
}

.keyresult-full-information-container .updates-container .updateText {
    display: inline-block;
    margin-bottom: 10px;
    line-height: 20px;
}

.keyresult-full-information-container .updates-container .updated-when {
    font-size: 10px;
    float: left;
}

.keyresult-full-information-container .updates-container .updated-when b {
    font-weight: 600;
}

.keyresult-full-information-container .rounded-white-container {
    border-radius: 100%;
    background-color: rgb(179, 74, 111);
    width: 30px;
    height: 30px;
    color: white;
    padding-top: 2px;
    padding-left: 3px;
}

.keyresult-full-information-container .percent-done {
    position: absolute;
    top: 10px;
    left: 650px;
    font-size: 12px;
    background-color: #3D9DEA;
    color: white;
    width: 40px;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
    height: 40px;
    border-radius: 100%;
    font-weight: 800;
}

.keyresult-full-information-container .add-update-ui-container {
    margin-bottom: 20px;
}

.keyresult-full-information-container .add-update-ui-buttons-container {
    margin-top: 20px;
    float: right;
    margin-bottom: 20px;
}

.keyresult-full-information-container .add-update-ui-buttons-container .p-button {
    margin-left: 20px;
}

.keyresult-full-information-container .add-update-ui-new-measurable-result {
    margin-top: 10px;
    line-height: 20px;
}

.keyresult-full-information-container .add-update-ui-new-measurable-result .p-inputtext {
    width: 100px;
    text-align: center;
    margin-left: 10px;
}

.keyresult-full-information-container .error-message {
    color: red;
}