.figure-container {
    position: relative;
    width: 100%;
    display: inline-block;
    font-family: 'Roboto Condensed', serif;
}

.strategic-goal-polygon-container {
    position: absolute;
    z-index: 12;
    filter: drop-shadow(-1px 6px 6px rgba(50, 50, 0, 0.8));
}

.strategic-goal-polygon-container .strategic-goal-polygon {
    position: absolute;
    top: 0;
    left: 0;
    height: 500px;
    clip-path: polygon(0 0, 75% 0, 100% 50%, 75% 100%, 0 100%);
    background-color: #1d8abb;
}

.strategic-goal-polygon-container .strategic-text-container {
    font-size: 30px;
    color: white;
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.strategic-goal-polygon-container .strategic-text {
    width: 80%;
    text-transform: uppercase;
}

.strategic-goal-polygon-container .objective-polygon-container {
    position: absolute;
    z-index: 6;
    filter: drop-shadow(-1px 6px 6px rgba(50, 50, 0, 0.5));
}

.objective-polygon {
    position: absolute;
    left: 370px;
    height: 250px;
    clip-path: polygon(0 0, 75% 0, 100% 50%, 75% 100%, 0 100%);
    background-color: #1eb3dc;
    border-top: 1px solid #1d8abb;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.3);
}

.objective-text-container {
    font-size: 25px;
    color: white;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    text-transform: uppercase;
}

.objective-text {
    width: 60%;
    text-align: left;
}

.figure-container .key-result-container {
    position: absolute;
    z-index: 1;
    left: 900px;
}

.key-result-text-container {
    /*background-image: linear-gradient(90deg, rgb(35, 121, 155), rgb(21, 75, 96));*/
    background-color: rgb(35, 121, 155);
    border-radius: 5px;
    color: white;
    padding-left: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: normal;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.3);
}

/*.key-result-percent-container {*/
/*    position: absolute;*/
/*    width: 55px;*/
/*    height: 55px;*/
/*    font-size: 18px;*/
/*    left: 450px;*/
/*    top: 5px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    background-color: white;*/
/*    border-radius: 100%;*/
/*    color: gray;*/
/*    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.2);*/
/*    opacity: 0.7;*/
/*}*/

.key-result-percent-container {
    position: absolute;
    z-index: 1;
    width: 30px;
    height: 30px;
    font-size: 12px;
    top: -12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 100%;
    color: white;
    box-shadow: 0 0.2rem 1rem rgba(0,0,0,0.2);
}

.key-result-percent-container-earlier-stage {
    position: absolute;
    z-index: 1;
    width: 30px;
    height: 30px;
    font-size: 12px;
    top: -12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: gray;
    border-radius: 100%;
    color: white;
    box-shadow: 0 0.2rem 1rem rgba(0,0,0,0.2);
}

.key-result-progress-bar {
    position: absolute;
    top: 0;
    height: 6px;
    background-color: white;
    opacity: 1;
}

.key-result-progression-percent-point {
    position: absolute;
    width: 55px;
    height: 55px;
    font-size: 18px;
    left: 450px;
    top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    /*background-color: white;*/
    /*border-radius: 100%;*/
    /*box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.2);*/
}

.key-result-progression-percent-point-text {
    font-size: 25px;
    color: white;
}