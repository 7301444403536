.strategic-goal-progress-report-selection-container {
    font-family: Poppins, serif;
    margin-left: 70px;
    margin-top: -8px;
}

.strategic-goal-progress-report-selection-container .page-explanation {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 50px;
    
}

.strategic-goal-progress-report-selection-container .link-button {
    height: 50px;
    background-color: #1d8abb;
    color: white;
    border-radius: 5px;
    width: 600px;
    margin-bottom: 15px;
    padding: 0 10px 0 20px;
    display: flex;
    align-items: center;
    justify-content: left;
}

.strategic-goal-progress-report-selection-container .link-button-text {
    margin-left: 10px;
}

.strategic-goal-progress-report-selection-container .link-button a {
    color: white;
    text-decoration: none;
}


.strategic-goal-render-container {
    position: relative;
}

#img-to-copy {
    width: 100%;
}