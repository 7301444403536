.add-activity-element-container {
    height: 35px;
    display: relative;
    margin-bottom: 30px;
}

.add-activity-element-container .choose-responsible-user-container {
    float: left;
    margin-left: 15px;
}

.add-activity-element-container .user-selector {
    width: 200px;
    top: 20px;
}

.add-activity-element-container .text-field-input-mode {
    float: left;
    width: 350px;
    height: 35px;
}

.add-activity-element-container .text-field-non-input-mode {
    width: 100%;
}

.add-activity-element-container .button-container {
    margin-top: 20px;
    height: 35px;
    float: right;
    text-align: right;
}

.add-activity-element-container .button-container Button {
    
}