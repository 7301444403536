.employee-list-header-container {
    width: 100%;
    height: 130px;
    text-align: center;
    font-family: Poppins, serif;
    margin-left: 30px;
    margin-bottom: 20px;
}

.employee-list-header-container .title {
    padding-top: 15px;
    font-size: 18px;
    font-weight: 600;
}

.employee-list-header-container .employee-image {
    display: flex;
    align-items: center;
    justify-content: center;
}

.employee-list-header-container .employee-image img {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    object-fit: cover;
}

.employee-list-header-container .employee-image div {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: #cccccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
}

.employee-list-header-container .subtitle {
    font-size: 12px;
    color: #babbba;
    text-transform: uppercase;
}

