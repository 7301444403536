:root {
    --primary-color: #1d8abb;
    --secondary-color: #e6eef9;
    --button-width: 170px;
}

.tab-navigation-container {
    font-family: Poppins, serif;
}

.tab-navigation-container .tabs {
    display: flex;
    position: relative;
    background-color: #fff;
    padding: 0.75rem;
    border-radius: 5px;
}

.tab-navigation-container .tabs * {
    z-index: 2;
}

.tab-navigation-container .glider {
    position: absolute;
    display: flex;
    height: 34px;
    width: var(--button-width);
    background-color: var(--secondary-color);
    z-index: 1;
    border-radius: 99px;
    transition: 0.25s ease-out;
}