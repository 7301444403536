.objective-overview-row-based-container {
    font-family: Poppins, serif;
}

.objective-overview-row-based-container .objective-card {
    margin-bottom: 20px;
    font-size: 20px;
    width: 300px;
    border-radius: var(--main-radius);
    border: 1px solid var(--border-toned-down);
    border-right: 5px solid var(--border-header-element);
    text-align: center;
    background-color: white;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 5px;
    margin-right: 50px;
}

.objective-overview-row-based-container .objective-header {
    font-weight: 600;
    margin-bottom: 5px;
}

.objective-overview-row-based-container .objective-subtitle {
    color: var(--text-toned-down);
}

.objective-overview-row-based-container .column-header {
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 20px;
}

.objective-overview-row-based-container .keyresult-list-td {
    display: table-cell;
    min-width: 317px;
}

.objective-overview-row-based-container .column-header-divider {
    border-radius: 2px;
    height: 2px;
    width: 90%;
    background-color: var(--dashoboard-column-header-separators);
    margin: 5px auto;
    margin-bottom: 20px;
}

.objective-overview-row-based-container .keyresult-list-container {
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 0;
}

.objective-overview-row-based-container .objective-subtitle {
    font-size: 14px;
}
