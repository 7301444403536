.progress-container {
    width: 100%;
    height: 10px;
}

.progress-container .progress-indicator {
    background-color: red;
    height: 100%;
    border-radius: 15px;
}

