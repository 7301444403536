.info-message-component-container {
    background-color: #1d8abb;
    color: white;
    border-radius: 5px;
    width: 350px;
    height: auto;
    border-left: 5px solid #3D9DEA;
}

.info-message-component-container table {
    height: 100%;
}

.info-message-component-container table td:nth-child(1) {
    width: 20px;
    padding-left: 20px;
    text-align: center;
}

.info-message-component-container table td:nth-child(2) {
}

.info-message-component-container td {
    vertical-align: middle;
    padding: 10px;
}

.info-message-component-container .header {
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    display: block;
}

.info-message-component-container .message {
    text-align: left;
    font-size: 12px;
}