.activity-edit-line-component-container {
    padding-bottom: 5px;
    min-height: 35px;
}

.activity-status {
    vertical-align: top;
}

.activity-edit-line-component-container .activity-text {
    font-size: 15px;
    color: #666162;
    font-family: 'Inter', sans-serif;
    vertical-align: top;
    line-height: 20px;
}

.activity-edit-line-component-container .activity-responsible-container {
    width: 30px;
    vertical-align: bottom;
}

.activity-edit-line-component-container .activity-responsible-container img {
    object-fit: cover;
}

.activity-edit-line-component-container .button-container {
    visibility: hidden;
    float: right;
}

.activity-edit-line-component-container:hover .button-container {
    visibility: visible;
}

.activity-edit-line-component-container .button-container Button {
    margin-right: 5px;
}

.activity-edit-line-component-container .choose-responsible-user-container {
    display: none;
}

.activity-edit-line-component-container .user-image {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin-right: 15px;
}

.activity-edit-line-component-container .missing-photo {
    min-width: 28px;
    min-height: 28px;
    border-radius: 50%;
    font-size: 9px;
    margin-right: 15px;
    background-color: #cccccc;
    display: inline-block;
    padding-top: 10px;
    text-align: center;
}

.activity-edit-line-component-container .edit-activity-input {
    height: 35px;
    width: 300px;
}

.activity-edit-line-component-container .user-selector {
    width: 200px;
}

