.user-list-row-container .user-row {
    margin-bottom: 15px;
    width: 1000px;
    height: 80px;
    background-color: white;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: left;
    align-items: center;
}

.user-list-row-container .user-row:hover {
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
    transform: scale(1.01);
    transition-duration: .3s;
    cursor: pointer;
}

.user-list-row-container .user-column {
    display: inline-block;
}

.user-list-row-container .user-image {
}

.user-list-row-container .user-image img {
    width: 55px;
    height: 55px;
    border-radius: 100%;
    vertical-align: middle;
    margin-left: 20px;
    margin-right: 20px;
    object-fit: cover;
}

.user-list-row-container .user-image div {
    width: 55px;
    height: 55px;
    background-color: #cccccc;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-left: 20px;
    margin-right: 20px;
}

.user-list-row-container .user-name {
    font-weight: 600;
    font-size: 15px;
    width: 300px;
}

.user-list-row-container .user-email {
    font-weight: 200;
    font-size: 12px;
}

.user-list-row-container .user-active-status {
    width: 100px;
    display: flex;
    justify-content: left;
    align-items: center;}

.user-list-row-container .user-active-status-active-symbol {
    width: 10px;
    height: 10px;
    background-color: green;
    border-radius: 100%;
    margin-right: 5px;
}

.user-list-row-container .user-active-status-inactive-symbol {
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 100%;
    margin-right: 5px;
}

.user-list-row-container .user-active-status-active {
    color: green;
}

.user-list-row-container .user-active-status-inactive {
    color: red;
}

.user-list-row-container .user-title {
    width: 250px;
    text-overflow: clip;
    margin-right: 15px;
}

.user-list-row-container .user-primary-team-information {
    width: 200px;
}

.user-list-row-container .user-kebab-menu-container {
    color: #cccccc;
    cursor: pointer;
}

.edit-user-dialog {
    width: 494px;
    height: 900px;
    border-radius: 50px;
}


