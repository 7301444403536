.keyresult-list-header-container {
    width: 100%;
    height: 80px;
    text-align: center;
    font-family: Poppins, serif;
    margin-bottom: 10px;
}

.keyresult-list-header-container .title {
    padding-top: 15px;
    font-size: 18px;
    font-weight: 600;
    height: 70px;
    overflow: hidden;
    text-overflow: "-";
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;

}

.keyresult-list-header-container .subtitle {
    font-size: 12px;
    color: #babbba;
    text-transform: uppercase;
}

