:root {
    --title-field-height: 28px;
}

.edit-container {
    padding: 0 20px 20px 20px;
}

.edit-container .input-field-title-container {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
    height: var(--title-field-height);
}

.edit-container .input-field-title-container .material-icons-outlined {
    height: 100%;
    line-height: var(--title-field-height);
    vertical-align: middle;
    float: right;
}

.edit-container .input-field-title-container .material-icons:hover {
    cursor: pointer;
}

.edit-container .input-field-title-container .input-field-title-text {
    height: 100%;
    line-height: var(--title-field-height);
    vertical-align: middle;
}

.edit-container .form-explanation {
    margin-bottom: 40px;
    font-weight: 100;
    padding-left: 15px;
    font-size: 14px;
}

.edit-container .left-separator-key-result {
    border-left: 5px solid var(--separator-key-result);
}

.edit-container .left-separator-objective {
    border-left: 5px solid var(--separator-objective);
}

.edit-container .left-separator-strategic-goal {
    border-left: 5px solid var(--separator-strategic-goal);
}

.edit-container .form-explanation p {
    margin-bottom: 5px;
}

.edit-container .field-wrapper {
    margin-bottom: 15px;
}

.edit-container .error-message {
    color: red;
}

.edit-container .input-number-field-title {
    font-size: 14px;
    padding-bottom: 5px;
    display: block;
    line-height: 25px;
}

.edit-container .number-container {
    width: 100%;
    position: relative;
}

.edit-container .number-input-field-container {
    display: inline-block;
    width: 250px;
    vertical-align: top;
}


#levelYellow  {
    border-left: 8px solid var(--yellow);
}

#levelGreen  {
    border-left: 8px solid var(--green);
}

.edit-container .help-container {
    display: inline-block;
    margin-bottom: 30px;
    float: right;
}

.edit-container .help-container span {
    line-height: 30px;
    vertical-align: middle;
}

.edit-container .button-container {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: white;
    width: 100%;
    height: 60px;
    border-top: 1px solid #ececee;
}

.edit-container .button-container button {
    float: right;
    margin-top: 10px;
    margin-right: 15px;
}