:root {
    --content-border-color: #dedede;
    --divider-border-color: #dedede;
    --section-header-color: black;
    --tab-switch-height: 120px;
}

.key-result-full-information-container2 {
    
}

.key-result-full-information-container2 .close-button {
    position: absolute;
    float: right;
    color: #99adad;
    line-height: 10px;
    margin-right: 10px;
    top: 10px;
    left: 750px;
    border-radius: 50%;
}

.key-result-full-information-container2 .close-button:hover {
    border: 1px solid #99adad;
}


.key-result-full-information-container2 .card-container {
    width: 800px;
    height: 1000px;
    margin: 0;
}

.key-result-full-information-container2 .top-header-container {
    width: 100%;
    position: relative;
    min-height: 200px;
}

.key-result-full-information-container2 .team-members-container {
    position: absolute;
    top: 70px;
}

.key-result-full-information-container2 .team-members-container img {
    border: 2px solid white;
}

.key-result-full-information-container2 .top-header-container .background-image {
    width: 100%;
    object-fit: cover;
    height: 100px;
    opacity: 1.5;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.key-result-full-information-container2 .top-header-container .user-image {
    position: absolute;
    left: 345px;
    top: 25px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #cccccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 800;
    border: 5px solid white;
}

.key-result-full-information-container2 .top-header-container .user-image img {
    border-radius: 50%;
    height: 120px;
    width: 120px;
    border: 5px solid white;
    object-fit: cover;
}

.key-result-full-information-container2 .responsible-team-tag-container {
    pointer-events:none;
    position: absolute;
    top: 910px;
    height: 40px;
    width: 100%;
    z-index: 5;
}

.key-result-full-information-container2 .responsible-team-tag {
    color: white;
    height: 40px;
    padding: 0 40px 0 40px;
    border-top-left-radius: var(--main-radius);
    border-top-right-radius: var(--main-radius);
}

.key-result-full-information-container2  .key-result-header {
    padding: 50px 20px 0 20px;
    text-align: center;
    font-size: 25px;
    font-weight: 600;
}

.key-result-full-information-container2 .objective-header {
    font-size: 15px;
    font-weight: 200;
    text-align: center;
    margin-top: 10px;
}

.key-result-full-information-container2 .content-body {
    margin-top: 20px;
    overflow-y: scroll;
    height: 750px;
    width: 100%;
}

.key-result-full-information-container2 .section-container {
    padding: 15px 25px 0 25px;
    background-color: white;
    margin: 0 25px 20px 25px;
    border: 1px solid #efeeee;
    border-radius: 10px;
    position: relative;
}

.key-result-full-information-container2 .section-header {
    font-size: 15px;
    font-weight: 500;
    color: var(--section-header-color);
    padding: 8px 20px 8px 0;
    border-radius: 8px;
    display: inline-block;
    margin-bottom: 10px;
    font-family: Poppins, serif;
}

.key-result-full-information-container2 .what-to-do-text {
    font-size: 15px;
    line-height: 1.429;
    letter-spacing: 0.15px;
    color: white;
    padding-bottom: 25px;
}

.key-result-full-information-container2 .activity-container {
    padding: 0 10px 20px 10px;
}

.key-result-full-information-container2 .activity-status {
    margin-right: 15px;
}

.key-result-full-information-container2 .add-button {
    position: absolute;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-size: 15px;
    font-weight: 600;
    color: black;
    width: 115px;
    height: 30px;
    background-color:rgba(100, 100, 111, 0.2);
    top: 0;
    left: 600px;
    line-height: 30px;
    cursor: pointer;
}

.key-result-full-information-container2 .update-container {
    padding: 10px;
}

.key-result-full-information-container2 .update-row {
    margin-bottom: 20px;
}

.key-result-full-information-container2 .update-text {
    position: relative;
    clear: both;
    display: inline-block;
    padding: 16px 80px 16px 20px;
    margin: 0 0 0 0;
    border-radius: 10px;
    color: white;
    margin-left: 80px;
}

.key-result-full-information-container2 .update-percent {
    color: white;
    position: absolute;
    font-size: 30px;
    left: 510px;
    top: 10px;
    font-weight: 800;
    text-align: right;
    width: 70px;
}

.key-result-full-information-container2 .update-text-green {
    background-color: var(--green);
}

.key-result-full-information-container2 .update-text-yellow {
    background-color: var(--yellow);
}

.key-result-full-information-container2 .update-text-red {
    background-color: var(--red);
}

.key-result-full-information-container2 .update-text-arrow {
    width: 0;
    height: 0;
    border-left: 18px solid transparent;
    border-right: 14px solid transparent;
    position: absolute;
    top: 5px;
    left: -25px;
    transform: rotate(120deg);
}

.key-result-full-information-container2 .update-text-arrow-green {
    border-top: 40px solid var(--green);
}

.key-result-full-information-container2 .update-text-arrow-yellow {
    border-top: 40px solid var(--yellow);
}

.key-result-full-information-container2 .update-text-arrow-red {
    border-top: 40px solid var(--red);
}

.key-result-full-information-container2 .green {
    background-color: #c0ce50;
}

.key-result-full-information-container2 .update-user-image {
    position: absolute;
    left: -85px;
    top: 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #cccccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border: 2px solid white;
}

.key-result-full-information-container2 .update-user-image img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.key-result-full-information-container2 .update-information {
    margin-top: 8px;
    margin-left: 100px;
    font-size: 10px;
    color: #666162;
}

.ui-panel .ui-panel-content {
    padding: 100px;
}

.key-result-full-information-container2 .tab-switch {
    position: absolute;
    width: 40px;
    height: var(--tab-switch-height);
    left: -41px;
    background-color: #f2f6fa;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    writing-mode: vertical-rl;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    padding-right: 5px;
    cursor: pointer;
    color: #cccccc;
}

.key-result-full-information-container2 .tab-switch:hover {
    font-weight: 800;   
    color: black;
}

.key-result-full-information-container2 .tab-switch-selected {
    color: black;
    font-weight: 800;
}

.key-result-full-information-container2 .tab-switch-activities-and-updates {
    top: 100px;
}

.key-result-full-information-container2 .tab-switch-definition {
    top: 225px;
}