.message-container {
    font-size: 40px;
    height: 80%;
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.row {
    /*text-align: center;*/
}

.message-container .message-headline {
    text-align: center;
    color: #6d6e6d;
}

.message-container img {
    height: 100px;
    width: 394px;
}


