.keyresult-list-container {
    width: 100%;
    float: left;
    position: relative;
    margin-left: 30px;
    padding-left: 20px;
}

.keyresult-list-container .keyresult-container {

}

.keyresult-list-container .keyresult-card-container:last-child {
    margin-bottom: 0;
}

.keyresult-list-container .keyresult-add-card-container {
    width: 300px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.keyresult-list-container .keyresult-add-card {
    background-color: #6d6e6d;
    border-radius: 100%;
    display: inline-block;
    position: relative;
    font-family: Poppins, serif;
    height: 60px;
    width: 60px;
    font-size: 40px;
    color: white;
    cursor: pointer;
}

.keyresult-list-container .keyresult-add-card-compact {
    background-color: #6d6e6d;
    border-radius: 100%;
    display: inline-block;
    position: relative;
    font-family: Poppins, serif;
    height: 30px;
    width: 30px;
    font-size: 20px;
    color: white;
    cursor: pointer;
    padding-left: 5px;
    padding-top: 3px;
}

.keyresult-list-container .keyresult-add-card #symbolContainer {
    position: absolute;
    top: 4px;
    left: 4px;
}

.keyresult-list-container .keyresult-add-card:hover {
    transform: scale(1.1);
    transition-duration: 0.2s;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
}

