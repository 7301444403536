.add-new-key-result-update-container {
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 6px;
}

.add-new-key-result-update-container .update-text-input-header {
    font-weight: 600;
    margin-bottom: 10px;
}

.add-new-key-result-update-container .button-container {
    height: 35px;
}

.add-new-key-result-update-container .obtained-result-container {
    margin-top: 10px;
}

.add-new-key-result-update-container .result-and-goal-input {
    height: 30px;
    border-radius: 6px;
    color: white;
    font-size: 16px;
    padding: 2px 0 2px 8px;
    width: 150px;
}

.add-new-key-result-update-container .obtained-result-container {
    float: none;
}

.add-new-key-result-update-container .new-result {
    border-radius: 6px;
    text-align: center;
}

.add-new-key-result-update-container .row {
    text-align: left;
    margin-bottom: 10px;
    font-weight: 600;
}

.add-new-key-result-update-container .col1 {
    width: 600px;
}

.add-new-key-result-update-container .col2 {
    float: right;
}

.add-new-key-result-update-container .error-message {
    color: red;
}