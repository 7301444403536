.help-container {
    margin-top: 50px;
    margin-left: 50px;
    width: 100%;
    position: relative;
}

.help-container .video-container {
    display: inline-block;
    margin-right: 30px;
    margin-bottom: 30px;
}
