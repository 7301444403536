.page-header-container {
    text-align: left;
    color: black;
    line-height: initial;
    font-weight: 400;
    font-family: "Roboto Condensed", serif;
    font-style: normal;
    font-size: 30px;
    margin-top: 10px;
    white-space: nowrap;
}


.page-header-container .page-name-container {
    padding: 5px 15px 5px 30px;
    background-color: #1d8abb;
    border-radius: 5px;
    text-transform: uppercase;
    color: white;
}

.page-header-container .page-name {
    padding-left: 20px;
}

.page-header-container .icon {
    position: absolute;
    left: 80px;
    color: white;
    font-size: 35px;
}

.page-header-container .organisation-name {
    text-transform: uppercase;
}


