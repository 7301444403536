.team-overview-column-based-container {
    font-family: Poppins, serif;
    z-index: 0;
}

.team-overview-column-based-container .teams-and-results-container {
    float: left;
    position: relative;
}

.teams-and-results-container .time-span-label-td {
    border-right: 4px solid #cccccc;
    vertical-align: middle;
}

.teams-and-results-container .keyresult-list-td {
    vertical-align: top;
    min-width: 317px;
}

.teams-and-results-container .spacer-row-between-time-periods {
    height: 50px;
}

.team-overview-column-based-container .time-span-label-container {
    position: static;
    writing-mode: tb-rl;
    transform: rotate(-180deg);
    top: 105px;
    bottom: 40px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    padding-left: 8px;
    width: 40px;
    color: #babbba;
}

.team-overview-column-based-container .keyresult-header-container {
    width: 315px;
    margin-bottom: 25px;
    float: left;
    position: relative;
    margin-left: 40px;
}

.filter-buttons-container {
    position: relative;
    margin-left: 25px;
}

.filter-button-container {
    display: inline-block;
    margin-right: 20px;
    text-align: center;
}

.filter-button-container label {
    display: block;
    margin-bottom: 5px;
    font-weight: 800;
}


