.filter-button-component-container {
    height: 80px;
    background-color: white;
    border-radius: 5px;
    position: relative;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-overflow: clip;
}

.filter-button-component-container .filter-button-switch-container {
    width: 75px;
    /*border: 1px solid darkred;*/
    padding-left: 10px;
    display: inline-block;
    text-align: left;
}

.filter-button-component-container .filter-button-text-container {
    display: inline-block;
    text-align: left;
    padding-right: 10px;
}

.filter-button-component-container .filter-button-text-title {
    font-weight: 600;
    font-size: 14px;
}

.filter-button-component-container .filter-button-text-description {
    font-weight: 400;
    font-size: 12px;
}