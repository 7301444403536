.user-multi-select-list-container {
}


#user_multi_select_list table thead {
    display: none;
}

#user_multi_select_list table td {
    border-bottom: 0;
    height: 40px;
    padding: 5px 0 5px 15px;
}

#user_multi_select_list .p-datatable-header {
    height: 70px;
    border: 0;
    background-color: white;
}

#user_multi_select_list input {
    height: 20px;
    width: 100%;
}

#user_multi_select_list input:hover, focus {
    border: 1px solid var(--input-outline-neutral);
}

#user_multi_select_list .p-datatable-footer {
    background-color: white;
    border-bottom: 0;
}

#user_multi_select_list .p-datatable-footer button {
    margin-bottom: 15px;
    float: right;
}

#user_multi_select_list .p-button-outlined {
    border: 1px solid var(--button-outline-neutral);
    background-color: white;
    color: var(--button-text-neutral)
}

#user_multi_select_list .p-button-outlined:hover {
    background-color: var(--button-neutral-hover);
}
