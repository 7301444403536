.keyresult-card-container {
    background-color: white;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 10px;
    display: block;
    position: relative;
    border-top: 1px solid #ececee;
    border-left: 4px solid #3D9DEA;
    border-right: 1px solid #ececee;
    border-bottom: 1px solid #ececee;
    font-family: Poppins, serif;
    height: 130px;
    transition: transform 300ms ease-in-out;
    max-width: 295px;
}

.keyresult-card-container:hover {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.4);
    transform: scale(1.05);
    cursor: pointer;
}

.keyresult-card-container .card-group {
    font-size: 12px;
    line-height: 14px;
    height: 42px;
    width: 230px;
    overflow: hidden;
    text-overflow: "-";
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    display: -webkit-box;
}

.keyresult-card-container .card-title {
    font-size: 15px;
    font-weight: 600;
    padding: 0 5px 5px 0;
    line-height: 20px;
    height: 60px;
    overflow: hidden;
    text-overflow: "-";
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    width: 270px;
    display: -webkit-box;
    margin-bottom: 5px;
}

.keyresult-card-container .card-details-button-container {
    display: inline-block;
    font-size: 12px;
    opacity: 0.6;
    margin-top: 30px;
    position: relative;
}

.keyresult-card-container .card-details-button-container a {
    text-decoration: none;
    color: #595959;
}

.keyresult-card-container .card-details-button-container a span {
    position: absolute;
    top: -3px;
}

.keyresult-card-container .days-since-updated {
    position: absolute;
    top: 109px;
    font-size: 10px;
    color: #595959;
}

.keyresult-card-container .edit-symbol {
    position: absolute;
    top: 35px;
    left: 112px;
    font-size: 35px;
    cursor: pointer;
    color: white;
    background-color: #6d6e6d;
    border-radius: 100%;
    width: 60px;
    height: 60px;
    line-height: 60px;
    vertical-align: middle;
    text-align: center;
}

.keyresult-card-container .percent-done {
    position: absolute;
    top: 80px;
    left: 240px;
    font-size: 16px;
    background-color: #3D9DEA;
    color: white;
    padding-top: 8px;
    width: 40px;
    text-align: center;
    height: 40px;
    border-radius: 100%;
}

.keyresult-card-container .responsible-person img {
    position: absolute;
    top: 80px;
    left: 190px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    object-fit: cover;
}

.keyresult-card-container .responsible-person div {
    position: absolute;
    top: 80px;
    left: 190px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #cccccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
}


.faded {
    opacity: 0.2;
}

.dialog-button-text {
    font-size: 12px;
}

