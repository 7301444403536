:root{
    --bg-shape-color:linear-gradient(120deg,  #343A4F, #0F1620) ;
    --lightblue: #3D9DEA;
    --darkblue: #4A4EEE;
    --text-color: #D5E1EF;
    --min-card-height: 250px;
}

.card-container {
}

.card {
    background-color: white;
    border-radius: 5px;
    padding: 15px;
    display: block;
    position: relative;
    border-top: 1px solid #ececee;
    border-left: 8px solid #6d6e6d;
    border-right: 1px solid #ececee;
    border-bottom: 1px solid #ececee;
    font-family: Poppins, serif;
    height: 260px;
    margin: 10px;
    overflow: hidden;
    width: 300px;
    transition: all .2s ease-in-out;
}

.card-header {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
    min-height: 40px;
}

.card-strategic-goal {
    font-size: 14px;
    font-weight: 400;
    color: #babbba;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.card-description {
    font-size: 14px;
    font-weight: 200;
    color: #6d6e6d;
    overflow: hidden;
    max-height: 160px;
}

.card:hover {
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.4);
    transform: scale(1.05);
    transition-duration: .2s;
    cursor: pointer;
}

.card:hover svg {
    transform: scale(1.1);
    transition-duration: 4s;
}

.card:hover .card-add-text {
    display: block;
}

.card-add-text {
    color: #cccccc;
    font-size: 15px;
    display: none;
}

.card-add {
    color: #cccccc;
    width: 100%;
    text-align: center;
    display: inline-block;
    padding-top: 60px;
}

.card-add-content {
    display: table-cell;
    vertical-align: middle;
}

.card-add-content .material-icons {
    font-size: 80px;
    color: var(--button-inactive-state-color);
}

.card-add:hover .material-icons {
    opacity: 0.3;
    transform: scale(1.05);
    transition-duration: .2s;
}

.card-header img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    min-height: var(--min-card-height);
}

.tag {
    background: #cccccc;
    border-radius: 50px;
    font-size: 12px;
    margin: 0;
    color: #fff;
    padding: 2px 10px;
    text-transform: uppercase;
    cursor: pointer;
}

.card-body p {
    font-size: 13px;
    margin: 0 0 40px;
    text-align: left;
}
.user {
    display: flex;
    margin-top: auto;
    text-align: left;
}

.user img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}
.user-info h5 {
    margin: 0;
}
.user-info small {
    color: #545d7a;
}

.p-dialog {
    background-color: #cccccc;
}

.p-dialog-header {
    background: #cccccc;
    border-top-left-radius: 0;
    box-shadow: 0 2px 5px 0;
}

