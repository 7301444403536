.tab-navigation-button-container {
    
}

.tab-navigation-button-container input[type="radio"] {
    display: none;
}

.tab-navigation-button-container .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    width: var(--button-width);
    font-size: 16px;
    font-weight: 300;
    border-radius: 99px;
    cursor: pointer;
    transition: color 0.15s ease-in;
}

.tab-navigation-button-container .notification {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    margin-left: 0.75rem;
    border-radius: 50%;
    background-color: var(--secondary-color);
    transition: 0.15s ease-in;
}

.tab-navigation-button-container input[type="radio"]:checked + label {
    color: var(--primary-color);
    font-weight: 500;
}

.tab-navigation-button-container input[type="radio"]:checked + label > .notification {
    background-color: var(--primary-color);
    color: #fff;
}

.tab-navigation-button-container label:hover {
    background-color: var(--secondary-color);
    opacity: 0.5;
}

.tab-navigation-button-container:hover .view-switcher {
    display: block;
}

.tab-navigation-button-container .view-switcher {
    display: none;
    position: absolute;
    top: 0;
    border-radius: 100%;
    background-color: #1d8abb;
    color: white;
    height: 25px;
    width: 25px;
    text-align: center;
    cursor: pointer;
}
