:root {
    --title-field-height: 28px;
}

.login-container {
    width: 100%;
    background: rgb(30,60,114);
    background: linear-gradient(175deg, rgba(30,60,114,1) 0%, rgba(42,82,152,1) 79%);
    height: 100vh;
    background-size: cover;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins, serif;
}

.login-container .login-card {
    width: 500px;
    background-color: white;
    border-radius: 15px;
    height: 700px;
    margin: 10px;
    text-align: center;
    opacity: 0.9;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.3);
    position: relative;
}

.login-container .VOKR-logo {
    position: absolute;
    left: 125px;
    top: 580px;
}

.login-container .logo-container {
    position: relative;
}

.login-container .payoff {
    position: absolute;
    font-size: 14px;
    top: 640px;
    left: 180px;
    font-family: Poppins, serif;
    color: #7b7b7b;
    text-transform: uppercase;
}

.login-container .forgot-password {
    font-size: 14px;
    font-weight: 600;
    color: black;
    text-decoration: underline;
    cursor: pointer;
    position: absolute;
    top: 378px;
    left: 328px;
}

.login-container .dialog-header {
    font-size: 26px;
    font-weight: 400;
    position: absolute;
    font-family: Poppins, serif;
    top: 50px;
    left: 200px;
}

.login-container .dialog-subtitle {
    font-size: 18px;
    position: absolute;
    top: 90px;
    left: 120px;
    color: #6d6e6d;
}

.login-container .form-container {
    margin-top: 150px;
    padding: 50px;
    justify-content: left;
}

.login-container .field-wrapper {
    margin-bottom: 25px;
}

.login-container .input-field-title-container {
    font-weight: 600;
    font-size: 14px;
    height: var(--title-field-height);
    text-align: left;
    color: #7b7b7b;
}

.login-container .error-message {
    color: red;
    position: absolute;
}

.login-container .login-button {
    position: absolute;
    top: 470px;
    width: 400px;
    display: block;
    border-radius: 25px;
    overflow: hidden;
    border: 0px solid;

    box-shadow: 0 5px 30px 0px rgba(3, 216, 222, 0.2);
    -moz-box-shadow: 0 5px 30px 0px rgba(3, 216, 222, 0.2);
    -webkit-box-shadow: 0 5px 30px 0px rgba(3, 216, 222, 0.2);
    -o-box-shadow: 0 5px 30px 0px rgba(3, 216, 222, 0.2);
    -ms-box-shadow: 0 5px 30px 0px rgba(3, 216, 222, 0.2);

    background: rgb(42,82,152);
    background: linear-gradient(90deg, rgba(42,82,152,1) 0%, rgba(30,60,114,1) 79%);

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login-container .login-button:hover {
    background: rgb(42,82,152);
    background: linear-gradient(90deg, rgba(30,60,114,1) 0%, rgba(42,82,152,1) 79%);
}

.login-container .login-button-background {
    position: absolute;
    z-index: -1;
    width: 300%;
    height: 100%;
    background: #a64bf4;
    background: -webkit-linear-gradient(right, #00dbde, #fc00ff, #00dbde, #fc00ff);
    background: -o-linear-gradient(right, #00dbde, #fc00ff, #00dbde, #fc00ff);
    background: -moz-linear-gradient(right, #00dbde, #fc00ff, #00dbde, #fc00ff);
    background: linear-gradient(to right, #00dbde, #fc00ff, #00dbde, #fc00ff);
    top: 0;
    left: -100%;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.p-password-input {
    width: 100%;
}

.p-dialog {
    width: 600px;
}

