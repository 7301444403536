.organisations-summary-container {
    background-color: white;
    padding: 15px;
    border-radius: 15px;
}

.organisations-summary-container .table-container {
    width: 100%;
    font-family: Roboto;
}

.organisations-summary-container .table-row {
    display: flex;
    margin-bottom: 5px;
}

.organisations-summary-container .table-column {
    font-size: 20px;
    color: white;
    padding: 15px;
    border-radius: 5px;
    margin-right: 15px;
    width: 200px;
    text-overflow: clip;
}

.organisations-summary-container .column-result {
    color: black;
    font-size: 20px;
    font-weight: 600;
}

.organisations-summary-container .row-header {
    color: black;
    text-align: left;
    min-width: 300px;
    margin-right: 30px;
    font-size: 20px;
}

.organisations-summary-container .gray {
    background-color: #eeeeee;
}

.organisations-summary-container .company-logo-container {
    transition: all .2s ease-in-out;
    cursor: pointer;
}

.organisations-summary-container .company-logo-container:hover {
    transform: scale(1.2);
}

.organisations-summary-container .column1-header {
    background-color: rgba(254, 208, 147, 1)
}

.organisations-summary-container .column1-content {
    background-color: rgba(254, 208, 147, 0.3)
}

.organisations-summary-container .column2-header {
    background-color: rgba(6, 146, 253, 1)
}

.organisations-summary-container .column2-content {
    background-color: rgba(6, 146, 253, 0.2)
}

.organisations-summary-container .column3-header {
    background-color: rgba(118, 219, 255, 1)
}

.organisations-summary-container .column3-content {
    background-color: rgba(118, 219, 255, 0.3)
}

.organisations-summary-container .column4-header {
    background-color: rgba(255, 131, 108, 1)
}

.organisations-summary-container .column4-content {
    background-color: rgba(255, 131, 108, 0.3)
}

.organisations-summary-container .column5-header {
    background-color: rgba(163, 44, 78, 1)
}

.organisations-summary-container .column5-content {
    background-color: rgba(163, 44, 78, 0.3)
}
