.button-tab-navigation-button-container {
    margin-right: 20px;
    border-radius: var(--button-border-radius);
    background-color: var(--button-tab-navigation-color);
    padding: var(--button-tab-navigation-padding);
    cursor: pointer;
}

.tab-selected {
    background-color: var(--button-tab-navigation-background-color-selected);
    color: var(--button-tab-navigation-foreground-color-selected);
}

.tab-disabled {
    color: #cccccc;
}

.button-tab-navigation-button-container .icon {
    
}

.button-tab-navigation-button-container .title {
    margin-left: 15px;
}