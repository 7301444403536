.team-overview-row-based-container {
    font-family: Poppins, serif;
}

.team-overview-row-based-container .team-card {
    margin-bottom: 20px;
    font-size: 20px;
    width: 300px;
    border-radius: 5px;
    margin-right: 50px;
}

.team-overview-row-based-container .column-header {
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 20px;
}

.team-overview-row-based-container .keyresult-list-td {
    display: table-cell;
    min-width: 317px;
}

.team-overview-row-based-container .column-header-divider {
    border-radius: 2px;
    height: 2px;
    width: 90%;
    background-color: var(--dashoboard-column-header-separators);
    margin: 5px auto;
    margin-bottom: 20px;
}

.team-overview-row-based-container .keyresult-list-container {
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 0;
}