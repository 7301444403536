.show-hide-filter-selector-container {
    margin-left: 20px;
    vertical-align: top;
    background-color: white;
    border-radius: 5px;
    height: 58px;
    padding: 0 15px 0 15px;
}

.subpage-selector-container {
}

.show-hide-filter-selector-container .material-icons {
    font-size: 40px;
}

.year-selector-container .material-icons {
    font-size: 60px;
}