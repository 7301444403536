:root {
    --title-field-height: 28px;
}

.edit-team-container {
    padding: 0 20px 20px 20px;
}

.edit-team-container .team-image {
    margin-top: 60px;
    text-align: center;
}

.edit-team-container .team-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-team-container .team-image-container img {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.3);
}

.edit-team-container .team-image img {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.3);
}

.edit-team-container .team-image-container div {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.3);
    background-color: #cccccc;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-team-container .input-field-title-container {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
    height: var(--title-field-height);
}

.edit-team-container .input-field-title-container .material-icons-outlined {
    height: 100%;
    line-height: var(--title-field-height);
    vertical-align: middle;
    float: right;
}

.edit-team-container .input-field-title-container .material-icons:hover {
    cursor: pointer;
}

.edit-team-container .input-field-title-container .input-field-title-text {
    height: 100%;
    line-height: var(--title-field-height);
    vertical-align: middle;
}

.edit-team-container .field-wrapper {
    margin-bottom: 15px;
}

.edit-team-container .error-message {
    color: red;
    margin-top: 5px;
}

.edit-team-container .input-number-field-title {
    font-size: 14px;
    padding-bottom: 5px;
    display: block;
    line-height: 25px;
}

.edit-team-container .number-container {
    width: 100%;
    position: relative;
}

.edit-team-container .number-input-field-container {
    display: inline-block;
    width: 250px;
    vertical-align: top;
}

.edit-team-container .help-container {
    position: absolute;
    top: -25px;
    left: 660px;
}

.edit-team-container .help-container span {
    line-height: 30px;
    vertical-align: middle;
}

.edit-team-container .p-button {
    margin-right: 5px;
    margin-top: 5px;
}

