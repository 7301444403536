.strategy-overview-container {
    font-family: Poppins, serif;
    z-index: 0;
    width: 1200px;
}

.strategy-overview-container .strategy-element-container {
    margin-bottom: 30px;
    vertical-align: top;
    display: inline-block;
    width: 1000px;
}

.strategy-overview-container .column-header-container div {
    font-family: Roboto, serif;
    font-size: 25px;
    font-weight: 600;
    height: 40px;
    margin-right: 50px;
    margin-bottom: 40px;
    text-align: center;
    display: inline-block;
    text-transform: uppercase;
    border-bottom: 1px solid #cccccc;
}

.strategy-overview-container .column-header-strategic-goal {
    width: 260px;
}

.strategy-overview-container .column-header-objectives {
    width: 260px;
}

.strategy-overview-container .column-header-key-results {
    width: 380px;
    margin-right: 0;
    display: inline-block;
}

.strategy-overview-container .card-basic-ui {
    background-color: white;
    border: 2px solid #cccccc;
    border-radius: 10px;
}

.strategy-overview-container .strategic-goal-card {
    min-height: 80px;
    width: 260px;
    overflow: hidden;
    padding: 0 10px 0 10px;
    vertical-align: middle;
    margin-bottom: 10px;
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    float: left;
    margin-right: 50px;
}

.strategy-overview-container .strategic-goal-card p {
    display: inline-block;
    line-height: normal;
    vertical-align: middle;
}

.strategy-overview-container .objectives-container {
    float: left;
    margin-right: 50px;
}

.strategy-overview-container .objective-card {
    min-height: 80px;
    width: 260px;
    overflow: hidden;
    padding: 0 10px 0 10px;
    margin-bottom: 10px;
    vertical-align: middle;
    font-weight: 600;
    text-align: center;
}

.strategy-overview-container .objective-card p {
    display: inline-block;
    line-height: normal;
    vertical-align: middle;
}

.strategy-overview-container .keyresult-container {
    float: left;
    width: 380px;
    margin-bottom: 10px;
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
}

.strategy-overview-container .keyresult-card {
    background-color: white;
    border: 1px solid #ececee;
    height: 40px;
    line-height: 40px;
    width: 360px;
    overflow: hidden;
    padding: 0 10px 0 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.strategy-overview-container .keyresult-card .quarter {
    background-color: #ececee;
    color: #6d6e6d;
    height: 30px;
    line-height: 30px;
    margin-right: 10px;
    margin-top: 5px;
    width: 30px;
    float: left;
    border-radius: 100px;
    text-align: center;
}
