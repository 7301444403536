.keyresult-card-compressed-container {
    background-color: white;
    border-radius: 5px;
    padding: 0 15px 0 15px;
    margin-bottom: 5px;
    display: block;
    position: relative;
    border-top: 1px solid #ececee;
    border-left: 4px solid #3D9DEA;
    border-right: 1px solid #ececee;
    border-bottom: 1px solid #ececee;
    font-family: Poppins, serif;
    height: 30px;
}

.keyresult-card-compressed-container:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
    transform: scale(1.05);
    transition-duration: .2s;
    cursor: pointer;
}

.keyresult-card-compressed-container .card-group {
    font-size: 12px;
}

.keyresult-card-compressed-container .card-title {
    font-size: 14px;
    font-weight: 400;
    text-overflow: clip;
    width: 238px;
    line-height: 30px;
    max-height: 30px;
    overflow: hidden;
}

.keyresult-card-compressed-container .card-details-button-container {
    display: inline-block;
    font-size: 12px;
    opacity: 0.6;
    margin-top: 30px;
    position: relative;
}

.keyresult-card-compressed-container .card-details-button-container a {
    text-decoration: none;
    color: #595959;
}

.keyresult-card-compressed-container .card-details-button-container a span {
    position: absolute;
    top: -3px;
}

.keyresult-card-compressed-container .edit-symbol {
    position: absolute;
    top: 0;
    left: 130px;
    font-size: 12px;
    cursor: pointer;
    color: white;
    background-color: #6d6e6d;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    vertical-align: middle;
    text-align: center;
}

.keyresult-card-compressed-container .percent-done {
    position: absolute;
    top: 0;
    left: 250px;
    font-size: 10px;
    background-color: #3D9DEA;
    color: white;
    padding-top: 7px;
    width: 28px;
    text-align: center;
    height: 28px;
    border-radius: 100%;
}

.faded {
    opacity: 0.2;
}

.dialog-button-text {
    font-size: 12px;
}

