/*.progress-symbol-container {*/
/*    min-height: 100vh;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    min-width: 100vh;*/
/*    z-index: 10;*/
/*}*/

.progress-symbol-container {
    position: fixed;
    width: 100%;
    height: 100%;
    margin: auto;
    left: 45%;
    top: 40%;
}

.progress-symbol {
    width: 250px;
    text-align: center;
    font-size: 18px;
}