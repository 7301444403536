:root {
    --image-width: 150px;
    --image-height: 150px;
}

.upload-user-image-container input[type="file"] {
    display: none;
}

.upload-user-image-container .custom-file-upload {
    border-radius: 50%;
    display: inline-block;
    position: relative;
    padding: 6px;
    cursor: pointer;
    background-color: #4388b7;
    margin-bottom: 25px;
}

.upload-user-image-container .img-wrap{
    position: relative;
    width: var(--image-width);
    height: var(--image-height);
    overflow: hidden;
    border-radius: 50%;
}

.upload-user-image-container .img-upload:before {
    font-family: 'Material Icons', serif;
    content: "\e2c3";
    font-size: 90px;
    position: absolute;
    padding-top: 60px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #4388b7;
    width: var(--image-width);
    height: var(--image-height);
    border-radius: 50%;
    opacity: 0;
    transition: .5s ease;
    background-color: #fff;
}

.upload-user-image-container .img-upload {
    background-color: #fff;
}

.upload-user-image-container .img-upload:hover:before{
    opacity: 1;
}

.upload-user-image-container img {
    width: auto;
    height: 100%;
}

