.node-to-png-dialog-container {
    float: right;
}

.node-to-png-dialog-container span {
    cursor: pointer;
    margin-left: 5px;
}

.node-to-png-image-container {
    margin-top: 40px;
    padding: 15px 25px 25px 25px;
    /*height: 30vw;*/
    width: 100%;
    border-radius: 15px;
}

.image-dialog {
    min-height: 20vw;
    min-width: 50vw;
}

.instruction-text {
    float: left;
    font-size: 20px;
}
