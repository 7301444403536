.add-button-container {
    background-color: #6d6e6d;
    border-radius: 100%;
    display: inline-block;
    position: relative;
    font-family: Poppins, serif;
    height: 50px;
    width: 50px;
    color: white;
    cursor: pointer;
}

.add-button-container  #symbolContainer {
}

.add-button-container:hover {
    transform: scale(1.1);
    transition-duration: 0.2s;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
}