.update-history-container {
    margin-bottom: 50px;
    margin-left: 50px;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*sans-serif;*/
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.update-history-container .header {
    font-size: 33px;
    font-weight: 600;
    color: black;
    width: 700px;
}

.update-history-container .sub-header {
    font-size: 20px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 50px;
}

.update-history-container .user-information-container {
    margin-bottom: 10px;
    text-align: left;
}

.update-history-container .user-image-container {
    
}

.update-history-container .user-image-container img {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    position: inherit;
}

.update-history-container .user-image-container div {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    position: inherit;
    background-color: #cccccc;
    font-size: 20px;
    font-weight: 600;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

.update-history-container .update-information-text {
    margin-left: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 16px;
}

.update-history-container .update-text {
    position: relative;
    margin-top: 20px;
    max-width: 660px;
    background-color: #fff;
    padding: 1.125em 1.5em;
    font-size: 20px;
    border-radius: 1rem;
    box-shadow:	0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);
    margin-bottom: 100px;
    color: black;
    font-weight: 400;
}

.update-history-container .update-text::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: 100%;
    left: 1.5em;
    border: .75rem solid transparent;
    border-top: none;
    border-bottom-color: #fff;
    filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));
}


.update-history-container .update-information-text-key-result-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
    color: black;
    font-family: 'Inter', sans-serif;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*sans-serif;*/
    /*-webkit-font-smoothing: antialiased;*/
    /*-moz-osx-font-smoothing: grayscale;*/
}

.update-history-container .update-information-text-objective-title {
    font-style: italic;
    color: black;
    font-family: 'Inter', sans-serif;
}

.update-history-container .update-information-text-date-information {
    font-size: 14px;
    font-style: italic;
    margin-top: 5px;
    color: black;
}

.update-history-container .update-information-percent-container {
    position: absolute;
    bottom: 0;
    right: 0;
}

.update-history-container .update-information-percent-confinement {
}

.update-history-container .update-information-percent-present {
    position: absolute;
    width: 55px;
    height: 55px;
    border-radius: 100%;
    font-size: 18px;
    background-color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin-left: 25px;
    top: -25px;
    left: -80px;
    z-index: 1;
}

.update-history-container .update-information-percent-before {
    position: absolute;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    font-size: 15px;
    background-color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    top: -20px;
    left: -90px;
}

.update-history-container .update-information-percent-container .material-icons {
    position: absolute;
    font-size: 50px;
    left: 32px;
    top: -3px;
    color: #cccccc;
}

.wrap {
    white-space: pre-wrap;      /* CSS3 */
    white-space: -moz-pre-wrap; /* Firefox */
    white-space: -pre-wrap;     /* Opera <7 */
    white-space: -o-pre-wrap;   /* Opera 7 */
    word-wrap: break-word;      /* IE */
}