html[xmlns] .clearfix {
    display: block;
}

* {
    -webkit-font-smoothing: antialiased;
}

:root {
    --main-radius: 5px;
    --main-padding: 5px;
    --main-menu-item-radius: 5px;
    --button-border-radius: 5px;
    --button-inactive-state-color: #cccccc;
    --global-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --body-background-color: rgb(248, 252, 255);
    --green: #557539;
    --yellow: #f1a23e;
    --red: #894f63;
    --button-color-save: #68ab79;
    --button-color-delete: #d86661;
    --button-color-close: #7a879b;
    --button-text-neutral: #495057;
    --button-outline-neutral: #cccccc;
    --button-neutral-hover: #f5f4f4;
    --input-text-neutral: #495057;
    --input-outline-neutral: #cccccc;
    --input-neutral-hover: #f5f4f4;
    --button-tab-navigation-color: white;
    --button-tab-navigation-background-color-selected: #1d8abb;
    --button-tab-navigation-foreground-color-selected: white;
    --button-tab-navigation-padding: 10px 20px 10px 20px;
    --dashoboard-column-header-separators: #cccccc;
    --separator-key-result: #bcad87;
    --separator-objective: #865425;
    --separator-strategic-goal: #5b727f;
    --border-toned-down: #ececee;
    --border-header-element: #7a879b;
    --text-toned-down: #7a879b;
}

/* basic element setup */

body {
    margin: 0;
    background-color: var(--body-background-color);
    font-family: var(--global-font);
}

h1 {
    font-size: 30px;
}

input {
    font-size: 15px;
    border-radius: 3px;
    padding: 6px;
    border: 1px solid rgb(166, 166, 166);
    transition: border-color 0.2s;
    outline: none;
}

input::placeholder {
    color: rgb(123, 123, 123);
    font-weight: 300;
}

input:focus {
    border-color: black;
}

input:hover {
    border-color: #3D9DEA;
}

/* basic element setup end */

.container {
    display: grid;
    height: 100vh;
    /*min-width: 2000px;*/
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1.5fr 30px;
    grid-template-areas:  "main main main main" "footer footer footer footer";
    font-family: "Poppins", serif;
    font-weight: 500;
    font-size: 14px;
    position: relative;
}

nav {
    background: white;
    grid-area: nav;
    padding-top: var(--main-padding);
    border-bottom: 1px solid #ececee;
}

main {
    background: #fafafa;
    grid-area: main;
    border-bottom: 1px solid #e4e6e7;
}

#sidebar {
    /*background-image: linear-gradient(45deg, rgb(109, 110, 109) 50%, rgb(137, 138, 137));*/
    background-color: #6d6e6d;
    color: white;
    display: inline-block;
    float: left;
    min-width: 260px;
    height: 100%;
    margin-right: 20px;
}

#content1 {
    background: white;
    grid-area: content1;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    border-bottom: 1px solid #e4e6e7;
    border-right: 1px solid #e4e6e7;
}

#content2 {
    background: white;
    grid-area: content2;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    border-bottom: 1px solid #e4e6e7;
    border-right: 1px solid #e4e6e7;
}

#content3 {
    background: white;
    grid-area: content3;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    border-bottom: 1px solid #e4e6e7;
    border-right: 1px solid #e4e6e7;
}

footer {
    background: white;
    grid-area: footer;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    border-bottom: 1px solid #e4e6e7;
    border-right: 1px solid #e4e6e7;
}

@media only screen and (max-width: 550px) {
    .container {
        grid-template-columns: 100%;
        grid-template-rows: 0.4fr 1fr 30px;
        grid-template-areas: 
      "sidebar"
      "main"
      "footer";
    }
}

.VOKR-logo {
    margin-top: 15px;
    margin-left: 50px;
    text-align: left;
}

.VOKR-logo img {
    width: 150px;
    height: 38px;
}

.sidebar-menu {
    margin-top: 10px;
    text-align: left;
}

.sidebar-menu li {
    box-sizing: content-box;
}

.sidebar-menu ul {
}

.sidebar-menu ul li {
    position: relative;
    list-style-type: none;
    border-radius: var(--main-menu-item-radius);
    height: 35px;
    padding-left: 10px;
    padding-top: 12px;
    margin-right: 20px;
}

.sidebar-menu li a {
    color: black;
    text-decoration: none;
    position: relative;
    width: 100%;
    display: flex;
}

.side-menu-section-header {
    font-size: 18px;
    margin-left: 50px;
    margin-top: 40px;
    font-weight: 500;
}

.sidebar-menu .title {
    padding-top: 2px;
    font-size: 16px;
}

.sidebar-menu li:hover {
    background-color: black;
}

.sidebar-menu li:hover a {
    color: white;
}

.sidebar-menu .icon {
    margin-right: 10px;
}

.sidebar-menu img {
    width: 20px;
    height: 20px;
}

.show-sidebar-icon-container {
    margin: 20px;
    float: left;
    cursor: pointer;
}

.subpage-navigation-container {
    margin-left: 50px;
}

.user-information-container {
    position: relative;
    font-family: Poppins, serif;
    text-align: center;
}

.user-information-container .user-image img {
    position: absolute;
    left: 110px;
    top: 50px;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    display: block;
    object-fit: cover;
}

.user-information-container .user-image div {
    position: absolute;
    left: 110px;
    top: 50px;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background-color: #cccccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
}

.user-information-user-name {
    position: absolute;
    font-size: 16px;
    width: 100%;
    text-align: center;
    top: 130px;
    font-weight: 600;
}

.user-information-logout-link {
    position: absolute;
    top: 155px;
    text-decoration: underline;
    text-align: center;
    width: 100%;
    cursor: pointer;
}

.user-information-change-password-link {
    position: absolute;
    top: 180px;
    text-decoration: underline;
    text-align: center;
    width: 100%;
    cursor: pointer;
}

/* Tools */
.notransformation {
    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
}

.fill-container-width {
    width: 100%;
}

.hidden-class {
    display: none;
}

.wrap {
    white-space: pre-wrap;      /* CSS3 */
    white-space: -moz-pre-wrap; /* Firefox */
    white-space: -pre-wrap;     /* Opera <7 */
    white-space: -o-pre-wrap;   /* Opera 7 */
    word-wrap: break-word;      /* IE */
}

.center-contents-vertically-horizontally {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.vertical-align-bottom {
    vertical-align: bottom;
}

.hide {
    display: none;
}

.show-all-table-borders td {
    border: 1px dotted #999;
}

/* Prime react adjustments */
.p-component {
    font-weight: 300;
}

.p-button-label {
    font-weight: 300;
}

/* General classes */
.green-text {
    color: var(--green);
}

.yellow-text {
    color: var(--yellow);
}

.emphasize-text {
    font-weight: 900;
}

.green-background {
    background-color: var(--green);
}

.yellow-background {
    background-color: var(--yellow);
}

.fade-in {
    animation-name: FadeIn;
    animation-duration: 3s;
    transition-timing-function: linear;
}

@keyframes FadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes FadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes FadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes FadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes FadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.center-contents-vertically {
    display: flex;
    align-items: center;
    justify-content: left;
}

.center-contents-horizontally {
    display: flex;
    justify-content: center;
}

.center-table-cell-content {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.align-contents-bottom {
    position: absolute;
    bottom: 0;
}

.hide {
    display: none;
}

.wrap {
    white-space: pre-wrap;      /* CSS3 */
    white-space: -moz-pre-wrap; /* Firefox */
    white-space: -pre-wrap;     /* Opera <7 */
    white-space: -o-pre-wrap;   /* Opera 7 */
    word-wrap: break-word;      /* IE */
}

.hide-overflow {
    overflow: hidden;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;
}

.grow { transition: all .2s ease-in-out; }
.grow:hover { transform: scale(1.05); }

.box-shadow {
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.3);
}

.pointer-cursor {
    cursor: pointer
}



/* General design elements */
.help-text {
    margin-top: 50px;
    font-size: 18px;
    width: 700px;
    margin-bottom: 50px;
    font-weight: 200;
    border-left: 5px solid #7b7b7b;
    padding-left: 20px;
}

.dialog-close-button {
    width: 25px;
    height: 25px;
    font-size: 20px;
}

/* General forms */
.help-access-text {
    font-size: 14px;
    text-decoration: underline;
    margin-left: 3px;
    cursor: pointer;
}

:root {
    --title-field-height: 28px;
}

.p-component {
    font-size: 14px;
}

.p-dropdown-label {
    font-size: 14px;
}

.p-dropdown-items {
    font-size: 14px;
    font-weight: 100;
    color: red;
}

.p-button {
    background-color: #cccccc;
    border-color: #B4B4B4;
}

.p-button:hover {
    background-color: #6d6e6d;
}

.p-button-save {
    border: 1px solid var(--button-color-save);
    background-color: var(--button-color-save);
}

.p-button-delete {
    border: 1px solid var(--button-color-delete);
    background-color: var(--button-color-delete);
}

.p-button-close {
    border: 1px solid var(--button-color-close);
    background-color: var(--button-color-close);
}

.p-button-outlined {
    border: 1px solid var(--button-outline-neutral);
    background-color: white;
    color: var(--button-text-neutral)
}

.p-button-outlined:hover {
    background-color: var(--button-neutral-hover);
}

.dialog-reset-header {
    padding: 0;
    background-color: transparent;
}

.dialog-reset-content {
    background-color: transparent;
    padding: 0;
}

.p-overlaypanel .p-overlaypanel-close {
    background-color: #cccccc;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #cccccc;
    background: #cccccc;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
    background: #f5f4f4;
    color: black;
}