:root {
    /*--grid-border-colour: rgba(#049cdb, .125)    */
}


.strategy-canvas-container {
    padding: 50px 0 0 40px;
    background-image: url(../../resources/basic-graphics/grid.png);
    background-repeat: repeat;
    background-size: 100px 100px;
    min-height: 100vh;
}

.strategy-canvas-container .strategy-canvas-contents {
    position: relative;
}

.strategy-canvas-container .VOKR-logo {
    margin-top: 0;
    margin-left: 25px;
    text-align: left;
    height: 30px;
}

.strategy-canvas-container .VOKR-logo img {
    width: 118px;
    height: 30px;
}

.strategy-canvas-container .top-bar {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    height: 50px;
    background-color: white;
    width: 100%;
    box-shadow: 0 5px 15px -3px rgba(0,0,0,0.1);
}

.strategy-canvas-container .top-bar .left-navigation-section {
    float: left;
    min-width: 200px;
    height: 100%;
}

.strategy-canvas-container .top-bar .left-navigation-section .title {
    margin-left: 20px;
    font-size: 30px;
    font-weight: 400;
    text-transform: uppercase;
}

.strategy-canvas-container .top-bar .center-navigation-section {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -10px;
    height: 100%;
}

.strategy-canvas-container .top-bar .center-navigation-section #gridOnOffButton {
    width: 80px;
}

.strategy-canvas-container .top-bar .center-navigation-section #chosenYear .p-inputtext {
    font-size: 12px;
    padding: 8px 0 0 5px;
}

.strategy-canvas-container .top-bar .right-navigation-section {
    float: right;
    min-width: 300px;
    height: 100%;
    justify-content: right;
}

.strategy-canvas-container .top-bar .close-button {
    float: right;
    color: #99adad;
    line-height: 10px;
    margin-right: 10px;
}

.strategy-canvas-container .container-table {
    margin-top: 30px;
}

.strategy-canvas-container .canvas-column-title {
    font-size: 30px;
    text-align: center;
    position: absolute;
}

.strategy-canvas-container .strategy-table-strategic-goal-column {
    width: 350px;
}

.strategy-canvas-container .strategy-table-objective-column {
    width: 350px;
}

.strategy-canvas-container .strategy-table-key-result-column {
    text-align: center;
}

.strategy-canvas-container .strategy-table-key-result-title {
    font-size: 30px;
}

.strategy-canvas-container .key-result-quarter-column {
    text-align: center;
}

.strategy-canvas-container .key-result-table {
}

/* Components css */
.strategy-canvas-container .canvas-card {
    position: absolute;
    background-color: white;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 10px;
    display: block;
    font-family: Poppins, serif;
    border: 1px solid #ececee;
    overflow: hidden;
}

.strategy-canvas-container .canvas-card-title {
    font-size: 15px;
    font-weight: 600;
    padding: 0 5px 0 5px;
    line-height: 20px;
    height: 100%;
}

.strategy-canvas-container .canvas-card-description {
    font-size: 12px;
    font-weight: 300;
    padding: 0 5px 5px 0;
    line-height: 15px;
    text-align: left;
}

.strategy-canvas-container .strategic-goal-card {
    height: 70px;
    width: 100%;
    padding: 0 15px 0 15px;
    background-color: #5b727f;
    border: 1px solid #5b727f;
    color: white;
    text-align: center;
}

.strategy-canvas-container .objective-card {
    height: 70px;
    width: 100%;
    background-color: #865425;
    border: 1px solid #865425;
    color: white;
}

.strategy-canvas-container .key-result-card {
    height: 70px;
    width: 100%;
    background-color: #bcad87;
    border: 1px solid #bcad87;
    color: white;
    overflow: visible;
}

.strategy-canvas-container .add-button-pop-out-container {
    position: absolute;
    z-index: 30;
    width: 100%;
    height: 60px;
    left: 0;
    cursor: pointer;
    border: 1px dashed;
    border-radius: 5px;
}

.strategy-canvas-container .add-button-pop-out {
    font-weight: 400;
    font-size: 25px;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    color: white;
    opacity: 0.5;
}

.strategy-canvas-container .strategic-goal-card-title {
    font-size: 17px;
    height: 100%;
    line-height: 20px;
}

.strategy-canvas-container .canvas-key-result-card-title {
    font-size: 14px;
    height: 100%;
    text-align: left;
    padding: 5px;
}

.strategy-canvas-container .add-card {
    margin-bottom: 40px;
    height: 20px;
}

.strategy-canvas-container .canvas-add-card-title {
    height: 100%;
}

.right-sidebar-header-name {
    font-size: 25px;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.right-sidebar-header {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;
}