.team-list-header-container {
    width: 100%;
    height: 130px;
    text-align: center;
    font-family: Poppins, serif;
    margin-left: 30px;
}

.team-list-header-container .title {
    padding-top: 15px;
    font-size: 18px;
    font-weight: 600;
}

.team-list-header-container .team-image img {
    object-fit: cover;
    width: 80px;
    height: 80px;
    border-radius: 100%;
}

.team-list-header-container .subtitle {
    font-size: 12px;
    color: #babbba;
    text-transform: uppercase;
}

