.strategic-goal-and-key-result-progression-container {
    
}

.strategic-goal-and-key-result-progression-container .goal-container {
    padding: 50px;
    border-radius: 50px;
    background-color: white;
    margin: 30px;
    width: 1550px;
    border: 1px solid #cccccc;
    box-sizing: content-box;
}

.strategic-goal-and-key-result-progression-container .progress-period {
    margin-left: 10px;
    margin-bottom: 50px;
    font-size: 16px;
    color: #6d6e6d;
    text-transform: uppercase;
}

.strategic-goal-and-key-result-progression-container .progress-period-leading-text {
    margin-left: 10px;
}

.strategic-goal-and-key-result-progression-container .strategic-goal-title {
    font-size: 50px;
    font-weight: 700;
    margin-left: 20px;
    font-family: 'Roboto Condensed', sans-serif;
}

.strategic-goal-and-key-result-progression-container .objective-title {
    font-size: 30px;
    margin-left: 25px;
    margin-bottom: 10px;
    width: 100%;
}

.strategic-goal-and-key-result-progression-container .key-result-container {
    height: 80px;
    color: white;
    position: relative;
    box-sizing: border-box;
}

.strategic-goal-and-key-result-progression-container .key-result-title-and-progress-container {
    background-color: #6d6e6d;
    height: 100%;
    width: 500px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    float: left;
}

.strategic-goal-and-key-result-progression-container .key-result-progress {
    font-size: 26px;
    padding-left: 20px;
    font-weight: 900;
    width: 120px;
    text-align: center;
}

.strategic-goal-and-key-result-progression-container .key-result-title {
    font-size: 20px;
    width: 350px;
    overflow: hidden;
    text-overflow: "-";
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;

}

.strategic-goal-and-key-result-progression-container .progress-container-background {
    width: 1000px;
    background-color: #e0ebf6;
    height: 100%;
    float: left;
    margin-left: 5px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
}

.strategic-goal-and-key-result-progression-container .progress-container {
    position: absolut;
    left: 0;
    top: 0;
    height: 100%;
}

.strategic-goal-and-key-result-progression-container .progress-container-percent {
    font-size: 30px;
    font-weight: 900;
    height: 100%;
    color: white;
    float: right;
    margin-right: 20px;
    position: relative;
    top: 0;
}

.strategic-goal-and-key-result-progression-container .progress-pointer-arrow-shape {
    position: absolute;
    height: 80px;
    width: 55px;
    clip-path: polygon(25% 25%, 100% 25%, 100% 65%, 25% 65%, 0 45%);
    transform: rotate(-90deg);
    color: white;
    font-size: 16px;
    padding-top: 25px;
    padding-left: 12px;
    top: -50px;
}

.strategic-goal-and-key-result-progression-container .progress-quarter-display {
    position: absolute;
    height: 45px;
    width: 45px;
    border-radius: 100%;
    font-size: 15px;
    background-color: #e0ebf6;
    top: -15px;
    left: -12px;
    z-index: 5;
    color: black;
    font-weight: 800;
}

.progress-pointer-arrow-color-before {
    background-color: #cccccc;
}

.green {
    background-color: #557539;
}

.green-left-to-right-gradient {
    background-image: linear-gradient(
            90deg,
            hsl(91deg 35% 25%) 0%,
            hsl(92deg 34% 28%) 14%,
            hsl(93deg 33% 31%) 29%,
            hsl(94deg 33% 34%) 43%,
            hsl(95deg 32% 37%) 57%,
            hsl(96deg 32% 40%) 71%,
            hsl(97deg 31% 44%) 86%,
            hsl(99deg 31% 47%) 100%
    );
}

.green-right-to-left-gradient {
    /*background-image: linear-gradient(*/
    /*        90deg,*/
    /*        hsl(99deg 31% 47%) 0%,*/
    /*        hsl(97deg 31% 44%) 14%,*/
    /*        hsl(96deg 32% 40%) 29%,*/
    /*        hsl(95deg 32% 37%) 43%,*/
    /*        hsl(94deg 33% 34%) 57%,*/
    /*        hsl(93deg 33% 31%) 71%,*/
    /*        hsl(92deg 34% 28%) 86%,*/
    /*        hsl(91deg 35% 25%) 100%*/
    /*);*/
    background-color: var(--green);
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
}

.yellow {
    background-color: #f1a23e;
}

.yellow-left-to-right-gradient {
    background-image: linear-gradient(
            90deg,
            hsl(41deg 99% 49%) 0%,
            hsl(40deg 91% 53%) 14%,
            hsl(40deg 88% 55%) 29%,
            hsl(40deg 85% 57%) 43%,
            hsl(40deg 82% 59%) 57%,
            hsl(40deg 78% 60%) 71%,
            hsl(41deg 75% 61%) 86%,
            hsl(41deg 71% 62%) 100%
    );
}

.yellow-right-to-left-gradient {
    /*background-image: linear-gradient(*/
    /*        90deg,*/
    /*        hsl(41deg 71% 62%) 0%,*/
    /*        hsl(41deg 75% 61%) 14%,*/
    /*        hsl(40deg 78% 60%) 29%,*/
    /*        hsl(40deg 82% 59%) 43%,*/
    /*        hsl(40deg 85% 57%) 57%,*/
    /*        hsl(40deg 88% 55%) 71%,*/
    /*        hsl(40deg 91% 53%) 86%,*/
    /*        hsl(41deg 99% 49%) 100%*/
    /*);*/
    background-color: var(--yellow);
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
}

.red {
    background-color: #894f63;
}

.red-left-to-right-gradient {
    background-image: linear-gradient(
            90deg,
            hsl(337deg 27% 26%) 0%,
            hsl(338deg 28% 30%) 14%,
            hsl(338deg 28% 34%) 29%,
            hsl(338deg 28% 38%) 43%,
            hsl(339deg 28% 42%) 57%,
            hsl(339deg 28% 47%) 71%,
            hsl(339deg 29% 51%) 86%,
            hsl(340deg 35% 55%) 100%
    );
}

.red-right-to-left-gradient {
    background-color: var(--red);
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
}

.vertical-spacer {
    margin-top: 50px;
}

.center-contents-vertically-horizontally {
    display: flex;
    align-items: center;
    justify-content: center;
}

.center-contents-vertically {
    display: flex;
    align-items: center;
    justify-content: left;
}
