.team-list-row-container {
    margin-bottom: 15px;
    width: 1000px;
    height: 80px;
    background-color: white;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: left;
    align-items: center;
}

.team-list-row-container:hover {
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
    transform: scale(1.01);
    transition-duration: .3s;
    cursor: pointer;
}

.team-list-row-container .team-column {
    display: inline-block;
    margin-left: 20px;
    margin-right: 20px;
}

.team-list-row-container .team-image img {
    width: 55px;
    height: 55px;
    border-radius: 100%;
    vertical-align: middle;
    margin-left: 20px;
    margin-right: 20px;
    object-fit: cover;
}

.team-list-row-container .team-title {
    font-size: 16px;
    width: 400px;
}

.team-list-row-container .team-color {
    border-radius: var(--main-radius);
    height: 40px;
    float: left;
    padding: 0 15px 0 15px;
    color: white;
    max-width: 405px;
}

.team-list-row-container .team-kebab-menu-container {
    color: #cccccc;
    cursor: pointer;
}
