.update-row {
    margin-bottom: 20px;
    width: 100%;
}

.update-row .update-text {
    position: relative;
    clear: both;
    display: inline-block;
    padding: 16px 80px 16px 20px;
    margin: 0 0 0 80px;
    border-radius: 10px;
    color: white;
    width: 598px;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    min-height: 50px;
}

.update-row .update-percent {
    color: white;
    position: absolute;
    font-size: 30px;
    left: 510px;
    top: 10px;
    font-weight: 800;
    text-align: right;
    width: 70px;
}

.update-row .update-text-green {
    background-color: var(--green);
}

.update-row .update-text-yellow {
    background-color: var(--yellow);
}

.update-row .update-text-red {
    background-color: var(--red);
}

.update-row .update-text-arrow {
    width: 0;
    height: 0;
    border-left: 18px solid transparent;
    border-right: 14px solid transparent;
    position: absolute;
    top: 0px;
    left: -20px;
    transform: rotate(120deg);
}

.update-row .updated-by-user-image img {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin-right: 10px;
    left: -85px;
    top: 0;
}

.update-row .updated-by-user-image div {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin-right: 10px;
    background-color: #cccccc;
    font-size: 15px;
    font-weight: 600;
    left: -85px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.update-row .update-user-image {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    left: -85px;
    top: 0;
}

.update-row .update-information {
    margin-top: 8px;
    margin-left: 100px;
    font-size: 10px;
    color: #666162;
}

.update-row .edit-symbol {
    padding: 0;
    font-size: 15px;
    cursor: pointer;
    color: white;
    background-color: #6d6e6d;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    line-height: 20px;
    vertical-align: middle;
    text-align: center;
    float: right;
}