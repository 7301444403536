.team-image-component-container .team-image img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    object-fit: cover;
}

.team-image-component-container .team-image {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #cccccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
}